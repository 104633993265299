import React, { useState, useEffect } from 'react';
import Card from './Card';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Testimonials = (props) => {
    let reviews = props.reviews;
    const [index, setIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function leftShiftHandler() {
        setIndex((index - 1 + reviews.length) % reviews.length);
    }

    function rightShiftHandler() {
        setIndex((index + 1) % reviews.length);
    }

    return (
        <div className='testimonials-container mx-auto flex flex-col items-center justify-center mt-5 p-5'>
            <div className='testimonials-navigation flex items-center justify-between w-full max-w-6xl'>
                <button 
                    onClick={leftShiftHandler}
                    className='testimonials-button text-white text-3xl hover:text-gray-400'>
                    <FiChevronLeft />
                </button>
                <div className={`testimonials-card flex items-center p-6 rounded-lg shadow-lg transition-all duration-500 ${isMobile ? 'flex-col' : 'flex-row'}`}>
                    <div className={`testimonials-image-container flex-shrink-0 mb-4 ${isMobile ? 'w-full' : 'mr-4'}`}>
                        <img src={reviews[index].image} alt={reviews[index].name} className={`testimonials-image w-${isMobile ? '40' : '60'} h-${isMobile ? '40' : '60'} object-cover rounded`} />
                    </div>
                    <Card review={reviews[index]} />
                </div>
                <button 
                    onClick={rightShiftHandler}
                    className='testimonials-button text-white text-3xl hover:text-gray-400'>
                    <FiChevronRight />
                </button>
            </div>
            <div className='testimonials-indicators flex mt-1 space-x-2'>
                {reviews.map((_, i) => (
                    <div
                        key={i}
                        className={`testimonials-indicator h-2 w-2 rounded-full ${i === index ? 'bg-white' : 'bg-gray-500'}`}
                    />
                ))}
            </div>
        </div>
    );
}

export default Testimonials;
