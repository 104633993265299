import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import madiga from "../assets/img/selected/madiga.png";
import tekemach from "../assets/img/selected/tekemach.png";
import aloft from "../assets/img/selected/aloft.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Projects = () => {
 
  const projects1 = [
    {
      title: "Madiga Resturant",
      description: "A beautiful website for Cafe",
      imgUrl: madiga,
      
    },
    {
      title: "Tekemach Furniture Shop",
      description: "",
      imgUrl: tekemach,
    },
    {
      title: "Aloft Incubation",
      description: "",
      imgUrl: aloft,
    },
  ];

  return (
    <section className="project" id="project">
      <Container className="allproject">
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Recent Works</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="second">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                  
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section> 
  )
}
