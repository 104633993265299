import {React,useEffect} from "react";
import { Footer } from "./Footer";
import { JobBanner } from "./JobBanner";
import { ProgramOverview } from "./ProgramOverview";
import HowItWorks from "./HowItWorks";
import { RegisterAffiliate } from "./RegisterAffiliate";
import Container from '@mui/material/Container';
import { useMotionTemplate, useMotionValue, motion, animate } from "framer-motion";

const COLORS_TOP = ["#141414", "#141414", "#000000", "#141414"];

export const JobOpportunities = () => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, [color]);

  const backgroundImage = useMotionTemplate`
    radial-gradient(
      circle at center,
      ${color} 10%,
      rgba(0, 0, 0, 0) 100%
    )
  `;

  return (
    <motion.section
      style={{
        backgroundImage,
      }}
      className="min-h-screen bg-gray-900 text-gray-200"
    >
      <JobBanner />
      <Container maxWidth="xl" style={{ marginBottom: '2rem' }}>
        <ProgramOverview />
        <HowItWorks />
        <RegisterAffiliate />
      </Container>
      <Footer />
    </motion.section>
  );
};