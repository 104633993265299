import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CampaignIcon from '@mui/icons-material/Campaign';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Container, Row, Col, Button } from "react-bootstrap";
import LinearProgress from '@mui/material/LinearProgress';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #ffffff 0%, #711E48 50%, #711E48 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #ffffff 0%, #711E48 50%, #711E48 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, #ffffff 0%, #711E48 50%, #711E48 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, #ffffff 0%, #711E48 50%, #711E48 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <HowToRegIcon />,
    2: <CampaignIcon />,
    3: <MonetizationOnIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiStepLabel-iconContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '8px',
  },
  '& .MuiStepLabel-label': {
    color: '#d2d2d2 !important',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '32px',
    fontWeight: 300,
    cursor: 'pointer',
    transition: 'color 0.3s',
    textAlign: 'center',
    '&:hover': {
      color: '#ff4081 !important',
    },
    '&.Mui-active': {
      color: '#FFFFFF !important',
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px', // Smaller font size for small screens
    },
  },
}));

const steps = [
  'Step 1: Register',
  'Step 2: Promote',
  'Step 3: Earn'
];

const stepDescriptions = [
  'Step 1: The first step is to sign up for our partner program. It’s fast, easy, and free! Once registered, you will be able to start promoting our products directly to your personal network.',
  'Step 2: After registering, you can begin sharing information about our services and products with your friends, family, or any potential clients.',
  'Step 3: For every sale or client that comes through your efforts, you’ll earn a commission. The more people you introduce to our services, the more you can earn.'
];

const DescriptionCard = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: 8,
  border: 'solid 0.2px white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  margin: '20px auto',
  maxWidth: '90%',
  textAlign: 'justify',
  color: 'white',
}));

export default function HowItWorks() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
  };

  return (
    <section className="job">
      <Container className="mx-auto px-4 py-8">
        <Row>
          <Col xs={12} className="text-center mb-8">
            <h2 className="text-4xl">How It Works</h2>
          </Col>
          <Col xs={12}>
            <Box className="w-full px-2">
              <Stepper activeStep={activeStep} connector={<ColorlibConnector />} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => handleStepClick(index)}>
                    <CustomStepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </CustomStepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Col>
          <Col xs={12}>
            <DescriptionCard>
              <p className="text-lg"style={{ 'fontFamily': 'Poppins', color:'#c7c7c7'}}>{stepDescriptions[activeStep]}</p>
            </DescriptionCard>
          </Col>
        </Row>
        <div className="flex justify-center mt-4">
          <button onClick={handleNext} className="butnext text-white py-2 px-4 rounded">
            Next →
          </button>
        </div>
      </Container>
    </section>
  );
}