import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Card = (props) => {
    let review = props.review;
    return (
        <Box 
            d="flex" 
            flexDirection="column" 
            alignItems="center" 
            position="relative" 
            w={{ base: '90%', sm: '20em', md: '25em', lg: '30em' }}
            bg="rgba(113, 30, 72, 0.2)" 
            borderRadius="1rem" 
            m="0.5rem"
            p={{ base: '1rem', md: '1.5rem' }}
        >
            <Box textAlign="center" mt="1" margin="0.2rem">
                <Text fontWeight="bold" textTransform="capitalize" fontSize={{ base: 'lg', md: 'xl' }} color="white" fontFamily="Poppins" marginTop='2em'>
                    {review.name}
                </Text>
                <Text textTransform="uppercase" fontSize={{ base: '12px', md: '14px' }} color="white">
                    {review.job}
                </Text>
            </Box>

            <Box mx="auto" mt="2" color="#ff389f">
                <Icon as={FaQuoteLeft} boxSize={{ base: '1.5em', md: '2em' }} />
            </Box>

            <Box textAlign="center" mt="2" color="#c5cacf" padding="20px" fontFamily="Poppins" className='text-justify'>
                <Text fontSize={{ base: 'sm', md: 'md' }} lineHeight={{ base: '1.4', md: '1.6' }}>
                    {review.text}
                </Text>
            </Box>

            <Box mx="auto" mt="2" color="#ff389f">
                <Icon as={FaQuoteRight} boxSize={{ base: '1.5em', md: '2em' }} />
            </Box>
        </Box>
    );
}

export default Card;
