import React, { useState } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About } from "./components/About";
import {JobOpportunities} from "./components/JobOpportunities"
import { Home } from "./components/Home";
import { Shop } from './components/Shop';
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import AnimatedCursor from "react-animated-cursor"
function App() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };
  return (
<div className="App">
<AnimatedCursor
          color="255,255,255"
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={1}
          hasBlendMode={true}
          outerStyle={{
            mixBlendMode: 'exclusion'
          }}
          innerStyle={{
            backgroundColor: 'var(--cursor-color)',
            mixBlendMode: 'exclusion'
          }}
        />
<Router>
<Navbar toggle={toggle} />
<Sidebar isopen={isopen} toggle={toggle} />
<Routes>
  <Route exact path="/" element={<Home />}></Route>
</Routes>
<Routes>
  <Route  path="/Banner" element={<Banner></Banner>}></Route>
</Routes>
<Routes>
  <Route  path="/Skills" element={<Skills></Skills>}></Route>
</Routes>
<Routes>
  <Route  path="/Projects" element={<Projects></Projects>}></Route>
</Routes>
<Routes>
  <Route  path="/Contact" element={<Contact></Contact>}></Route>
</Routes>
<Routes>
  <Route  path="/About" element={<About></About>}></Route>
</Routes>
<Routes>
  <Route  path="/JobOpportunities" element={<JobOpportunities></JobOpportunities>}></Route>
</Routes>
<Routes>
  <Route  path="/Footer" element={<Footer></Footer>}></Route>
</Routes>
<Routes>
  <Route path='/ProductShop' element={<Shop></Shop>}></Route>
  </Routes>
</Router>
     
    </div>
  );
}

export default App;
