import React from "react";
import headerImg from "../assets/img/perspectiv.png";
import { Footer } from "./Footer";
import reviews from "./Testimonal/data";
import Testimonials from "./Testimonal/Testimonials";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export const About = () => {
  let navigate = useNavigate()
  const SeeMore = () =>{
    let path= '/contact'; 
    navigate(path)
  }
  return (
    <>
        <Container>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
          
            <h2 className="bannertitile">THIS IS PERSPECTIVE</h2>
            <br></br>
            <h6 className="aboutdescription text-justify">At Perspective, we are a women-led technology company that specializes in web development, custom software development, and product development. Our mission is to build comprehensive digital solutions tailored to meet the diverse needs of businesses. We are committed to creating ecommerce platforms, custom websites, and innovative digital services that help companies establish and scale their online presence with ease.
            </h6>
            
            <h6 className="aboutdescription text-justify"> Over the past year, we have dedicated ourselves to delivering high-quality, user-friendly products that simplify business operations. Whether you're a small startup or an established enterprise, we provide solutions that ensure seamless digital transitions, empowering businesses to thrive in the competitive online market.
            </h6>
         
            <br></br><br></br>
            <button className="but" onClick={SeeMore}>Work With US </button>
          </Col>
   
          <Col xs={12} md={6} xl={5}>
                   <img src={headerImg} alt="Header Img"/>  
          </Col>
        </Row>
        <div className="flex flex-col ustify-center items-center bg-black-200">
       
       <h1 className="clientstitle"> CLIENTS POV... </h1> 
       <Testimonials reviews={reviews}/>

   </div>
        
      </Container>
      
      <Footer></Footer>
    </>
  )
}
