import React, { useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useMotionTemplate, useMotionValue, motion, animate } from "framer-motion";

const COLORS_TOP = ["#141414", "#260a18", "#383737", "#260a18"];

export const JobBanner = () => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, [color]);

  const backgroundImage = useMotionTemplate`
    radial-gradient(
      circle at center,
      ${color} 10%,
      rgba(0, 0, 0, 0) 100%
    )
  `;

  return (
    <motion.section
      style={{
        backgroundImage,
      }}
      className="relative flex flex-col items-center justify-center min-h-screen bg-gray-900 px-4 py-24 text-gray-200"
    >
      <div className="text-center">
        <h2 className="text-sm font-semibold text-gray-400 mb-2">
          JOB OPPORTUNITIES
        </h2>
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
        REGISTER , PROMOTE & EARN!
        </h1>
        <p className="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto"style={{ 'fontFamily': 'Poppins'}}>
       Join our trusted partner program and start earning by promoting our fantastic products and you’ll earn a commission for every sale made. It’s easy, flexible, and a great way to increase your income. Sign up today and take the first step towards a rewarding opportunity!        </p>
      </div>
      
    </motion.section>
  );
};