import React, { useState, useEffect, useRef } from "react";
import cafe from "../assets/img/Shop/cafe.png";
import company from "../assets/img/Shop/company.png";
import designer from "../assets/img/Shop/designer.png";
import photography from "../assets/img/Shop/photo.png";
import { useNavigate } from "react-router-dom";

const slides = [
  {
    title: "Photography Portfolio",
    img: photography,
    buttonText: "Book Demo",
    link: "/shop/photographer",
    description:
      "Perfect for professional and hobbyist photographers, our beautifully designed photography portfolio templates let you showcase your work in style. Easily upload your images, add descriptions, and customize the layout to suit your aesthetic. From landscape to portrait photography, make an impact with your online presence.",
    tags: ["Photographers", "Creative", "Portfolios"],
  },
  {
    title: "Designer Portfolio Showcase",
    img: designer,
    buttonText: "Book Demo",
    link: "/shop/designer",
    description:
      "For graphic designers, illustrators, and creative professionals, our designer portfolio templates provide a platform to let your art speak for itself. With clean, modern designs and customizable layouts, you can highlight your best work, create project galleries, and leave a lasting impression on potential clients or employers.",
    tags: ["Designers", "Creative Showcase", "Portfolio"],
  },
  {
    title: "Restaurant & Cafe Website",
    img: cafe,
    buttonText: "Book Demo",
    link: "/shop/all-in-one",
    description:
      "Whether you're running a bustling cafe or a trendy restaurant, our website designs are built to help you attract more customers. Display your menu, highlight your special dishes, and allow customers to book tables or order online. Our templates are designed to bring your food and drinks to life online, with easy-to-use customization options and quick setup.",
    tags: ["Cafes", "Restaurants", "Online Menu"],
  },
  {
    title: "Corporate Business Solutions",
    img: company,
    buttonText: "Book Demo",
    link: "/shop/business",
    description:
      "Boost your business’s online presence with our professional corporate website templates. Whether you’re a startup or an established company, these designs are built to showcase your services, team, and products. Add testimonials, case studies, and a blog to keep your clients updated and engaged. Take the next step in growing your business with ease.",
    tags: ["Business", "Corporate", "Professional"],
  },
];

const ShopSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef(null);
  const totalSlides = slides.length;
  let navigate = useNavigate();
  const SeeMore = () => {
    let path = '/contact'; 
    navigate(path);
  };
  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, []);

  const startAutoSlide = () => {
    slideInterval.current = setInterval(() => {
      nextSlide();
    }, 10000);
  };

  const stopAutoSlide = () => {
    clearInterval(slideInterval.current);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  return (
    <section className="relative w-half h-[600px]">
      <div className="flex justify-center items-center h-full mb-8">
        <button
          onClick={prevSlide}
          className="absolute left-4 z-10 p-3 text-white bg-black/50 rounded-full hover:bg-black/80 transition-all"
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-4 z-10 p-3 text-white bg-black/50 rounded-full hover:bg-black/80 transition-all"
        >
          &#10095;
        </button>

        <div className="relative w-full h-full">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 flex flex-col md:flex-row transition-opacity duration-1000 ease-in-out ${
                currentSlide === index ? "opacity-100" : "opacity-0"
              }`}
            >
              {/* Left side - Image */}
              <div className="w-full md:w-1/2 relative overflow-hidden min-h-[250px]  h-full">
                <img
                  src={slide.img}
                  alt={slide.title}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Right side - Content */}
              <div className=" shop w-full md:w-1/2 flex flex-col justify-center p-4 md:p-16">
                <h2 className=" text-xl md:text-2xl font-bold text-white mb-2 md:mb-4 uppercase">
                  Poplular Products
                </h2>
                <h3 className=" mb-4 ">
                  {slide.title}
                </h3>
                <p
                  className="text-[#8b95a1] text-base text-sm  md:text-xl mb-4 md:mb-8 text-justify "
                >
                  {slide.description}
                </p>
                <button className="px-4 md:px-6 py-2 md:py-3 bg-transparent  text-[#711E48]] rounded-md hover:bg-[#711E48] hover:text-white transition-all mb-10"
                onClick={SeeMore}
                style={{
                  borderRadius: '0.1em',
                  border: 'solid 0.01em',
                  borderImage: 'linear-gradient(to right, rgb(255, 255, 255), #711E48) 1',
                  color:'white'
                }}>
                  {slide.buttonText} →
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

     
    </section>
  );
};

export default ShopSlider;
