import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { useState } from "react";
import Draft from './Draft';
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import { Promotion } from './Promotion';

export const Home = () => {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };
    return ( 
        <div> 
          <Navbar toggle={toggle} />
         <Sidebar isopen={isopen} toggle={toggle} />
           <Draft></Draft>
           <Banner></Banner>
           <Skills></Skills> 
           <Projects></Projects> 
           <Promotion></Promotion>
           <Contact></Contact> 
           <Footer></Footer>  
            
        </div>
    ) 
}
