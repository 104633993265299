import React from "react";
import videos from "../assets/img/video/video.mp4"

const ProductShowcase = () => {
 
  return (
    <section className="flex flex-col md:flex-row items-center justify-between  text-white p-8 md:p-16 relative"
    style={{gap:'7em',paddingTop:'10em'}}>
      {/* Left side - Text */}
      <div className="shop md:w-1/2">
      <h3 className="  mb-4 ">
        The Perfect Website for Your Unique Identity
        </h3>
        
        <p className="text-lg md:text-xl mb-4 text-sm text-justify " >
        Our shop provides beautifully crafted, ready-made website designs tailored to reflect your unique style and vision. Whether you're an individual or a small business, we offer stunning, affordable websites that are fast to launch, so you can stand out online without breaking the bank.        </p>
        <p className="text-lg md:text-xl text-sm text-justify" >
        Skip the long wait times and high costs of traditional web development — get a professionally designed website that speaks to your identity and is ready for you in no time.        </p>
      </div>

      <div className="w-full md:w-1/2">
        <div className="relative w-full h-0 pb-[56.25%] rounded-lg shadow-lg overflow-hidden">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={videos}
            title="Product Video"
            autoPlay
            loop
            muted
          ></video>
        </div>
      </div>
    </section>
  );
};

export default ProductShowcase;