import { useState } from "react";
import contactImg from "../assets/img/perspective.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    name: '',
    email: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("https://perspective.et/backend/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code === 200) {
      setStatus({ success: true, message: 'Message sent successfully'});
    } else {
      setStatus({ success: false, message: 'Something went wrong, please try again later.'});
    }
  };

  return (
    <section className=" contact py-16 md:py-24 " id="connect">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={`w-full ${isVisible ? "animate__animated animate__zoomIn" : ""}`} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </div>
          <div className="w-full md:w-1/2">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={`${isVisible ? "animate__animated animate__fadeIn" : ""}`}>
                  <h2> Get In Touch</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="text" value={formDetails.name} placeholder=" Name" onChange={(e) => onFormUpdate('name', e.target.value)} className="w-full  bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                    </div>
                    <div className="mb-4">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300 resize-none"></textarea>
                    </div>
                    <button type="submit" className="but" >{buttonText} </button>
                    {status.message && (
                      <p className={`mt-4 text-center ${status.success ? "text-green-500" : "text-red-500"}`}>{status.message}</p>
                    )}
                  </form>
                </div>
              }
            </TrackVisibility>
          </div>
        </div>
      </div>
    </section>
  )
}