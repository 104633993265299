import Container from '@mui/material/Container';
import { Footer } from './Footer';
import ShopSlider from './ShopSlider';
import ProductShowcase from './productShowcase';

export const Shop = () => {

  return (
    <>
    <Container maxWidth="xl">
    <ProductShowcase />
    <br></br><br></br>
    <ShopSlider />
    <br></br><br></br>
   
  </Container>
   <Footer />
   </>
  );
};