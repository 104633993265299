import React from "react";
import headerImg from "../assets/img/perlogo.png";
import { Container, Row, Col } from "react-bootstrap";

export const ProgramOverview = () => {
  return (
    <Container maxWidth="xl" style={{ margin: '0rem' }}>
      <Row className="job align-items-center">
        <Col xs={12} md={6} xl={7}>
          <h2 className="text-4xl mb-4">Program Overview</h2>
          <p className="text-lg  mb-4 text-justify" style={{ 'fontFamily': 'Poppins', color:'#c7c7c7'}}>
            In the Services Commission Program, affiliates can earn by bringing in clients for services such as UI/UX design, web development, and e-commerce. The commission starts at <span className="font-semibold "style={{color:'white'}}>10%</span> for each client, but for those who refer more than three clients in a month, the commission increases to <span className="font-semibold "style={{color:'white'}}>20%</span>, maximizing earning potential.
          </p>
          <p className="text-lg  mb-4 text-justify"style={{ 'fontFamily': 'Poppins', color:'#c7c7c7'}}>
            In the Template Shop Commission Program, affiliates can earn by selling premium templates. The base commission is <span className="font-semibold "style={{color:'white'}}>10%</span> per sale, and it doubles to <span className="font-semibold " style={{color:'white'}}>20%</span> when affiliates sell three or more templates in a month, offering a great opportunity for increased income.
          </p>
          <p className="text-lg  text-justify"style={{ 'fontFamily': 'Poppins', color:'#c7c7c7'}}>
            Both programs reward performance, allowing affiliates to grow their earnings as they bring in more clients or sell more templates. The key difference between the two is that the prices for the templates are already fixed, while service commissions are based on the specific projects clients bring in, giving affiliates a more predictable income from template sales.
          </p>
        </Col>

        <Col xs={10} md={0} xl={5} className="text-center">
          <img className="w-full  mx-auto" src={headerImg} alt="Header Img" />
        </Col>
      </Row>
    </Container>
  );
};