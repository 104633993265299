

import fitsum from "../../assets/img/testimonal/fitsum.jpg"
import amino from "../../assets/img/testimonal/amino.jpg"
import misge from "../../assets/img/testimonal/misge.jpg"
import phili from "../../assets/img/testimonal/phili.jpeg"
import abeni from "../../assets/img/testimonal/abeni.jpg"
import ab from "../../assets/img/testimonal/ab.jpg"

const reviews = [
  {
    id: 1,
    name: "Fitsum Workneh",
    job: "CEO of Kibirt design",
    image: fitsum,
    text: "Not only did they deliver on their promises, but they went above and beyond to create a visually stunning and user-friendly website that truly captures the essence of our brand. Their creativity, attention to detail, and dedication to excellence resulted in a website that not only showcases our products beautifully but also enhances the overall user experience."  },
  {
    id: 2,
    name: "Aderajew Asfaw",
    job: "General Manager, alela multimedia",
    image: amino,
    text: "We are extremely satisfied with the exceptional work carried out by the Perspective team. They have developed websites for our company and events, while also providing graphics design services that have surpassed our expectations. They truly understood our company's vision and transformed it into a captivating online presence, incorporating user-friendly interfaces, seamless navigation, and visually stunning design elements." },
  {
    id: 3,
    name: "Misganaw Melile",
    job: "Photographer",
    image: misge,
    text: "I recently had the pleasure of working with perspective to develop my portfolio website, and I am absolutely thrilled with the results. From start to finish, the experience was seamless, professional, and exceeded all my expectations."  },
  {
    id: 4,
    name: "Philipos Wondewesen",
    job: "CEO of iconnect",
    image: phili,
    text: "Perspective is incredibly productive and delivers quickly without compromising on quality. I'm thrilled to be working with them. They are easy to communicate with and truly understand my needs!"  },
  {
    id: 5,
    name: "Abenezer Anbessie",
    job: "Solution Engineer",
    image: abeni,
    text: "Working with Perspective was a game-changer for us. They delivered a stunning redesign of our website and mobile app, significantly improving user engagement and satisfaction. Their attention to detail and expertise in UI/UX design are unmatched. We couldn't be happier with the results and highly recommend perspective for any UI/UX projects." },
    {
      id: 6,
      name: "Abenezer Shewaga",
      job: "Photographer",
      image: ab,
      text: "Perspective's photography website product is stunning and easy to navigate. The galleries load quickly and showcase high-resolution images beautifully. Highly recommended!"}
  ];

export default reviews;
