import React from "react";

export const Skills = () => {
  const isSmallScreen = window.innerWidth < 576;
  return (
    <section style={{ padding: '2rem 0' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto',   marginTop: isSmallScreen ? '1em' : '6em',padding: '0 1rem' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '1.5rem' }}>
          <div style={{
            backgroundColor: '',
            padding: '1.5rem',
            borderRadius: '10px',
            boxShadow: '0 8px 15px rgba(0, 0, 0, 10)',
            transition: 'transform 0.3s',
            textAlign: 'center'
          }}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <h3 style={{ 
              fontSize: '1.7rem',
              backgroundImage: 'linear-gradient(to right, #ffffff, #711E48)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              marginBottom: '0.5rem' 
            }}>
              Web Application Development
            </h3>
            <h6 style={{ color: '#B8B8B8', 
             fontFamily: 'Poppins',
             fontSize: '18px',
             letterSpacing: '0.8px',
             lineHight: '1.7em'
            }}>
              Build robust, user-friendly web applications that elevate your business and engage your customers.
            </h6>
          </div>

          <div style={{
            backgroundColor: '',
            padding: '1.5rem',
            borderRadius: '8px',
            boxShadow: '0 8px 15px rgba(0, 0, 0, 10)',
            transition: 'transform 0.3s',
            textAlign: 'center'
          }}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <h3 style={{ 
              fontSize: '1.7rem',
              backgroundImage: 'linear-gradient(to right, #ffffff, #711E48)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              marginBottom: '0.5rem' 
            }}>Custom Software Development</h3>
            <h6 style={{ color: '#B8B8B8', 
             fontFamily: 'Poppins',
             fontSize: '15px',
             letterSpacing: '0.8px',
             lineHight: '1.7em'
            }}>
              Tailor-made software solutions designed to meet your unique business challenges and drive efficiency.
            </h6>
          </div>
          <div style={{
            backgroundColor: '',
            padding: '1.5rem',
            borderRadius: '8px',
            boxShadow: '0 8px 15px rgba(0, 0, 0, 10)',
            transition: 'transform 0.3s',
            textAlign: 'center'
          }}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <h3 style={{ 
              fontSize: '1.7rem',

              backgroundImage: 'linear-gradient(to right, #ffffff, #711E48)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              marginBottom: '0.5rem' 
            }}>
              Product Design And Development</h3>
            <h6 style={{ color: '#B8B8B8', 
             fontFamily: 'Poppins',
             fontSize: '15px',
             letterSpacing: '0.8px',
          
            }}>
              From concept to launch, we create innovative products that resonate with your audience and inspire loyalty.
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
};