import React, { useState, useEffect, useRef } from "react";
import HoverableHeading from "../components/headings/HoverableHeading";
import cafe from "../assets/img/Shop/cafe.png";
import company from "../assets/img/Shop/company.png";
import designer from "../assets/img/Shop/designer.png";
import photography from "../assets/img/Shop/photo.png";

const slides = [
  {
    title: "Photographer Website",
    img: photography,
    buttonText: "Explore Designs",
    link: "/ProductShop",
    description: "Easily get your own photography page with beautiful designs. Just add your photos and share!",
    tags: ["Photographers", "Portfolios"],
  },
  {
    title: "Designer Portfolio",
    img: designer,
    buttonText: "See Options",
    link: "/ProductShop",
    description: "Elegant templates for designers to showcase their creativity.",
    tags: ["Designers", "Showcase", "Creativity"],
  },
  {
    title: "Cafe Websites",
    img: cafe,
    buttonText: "Get Started",
    link: "/ProductShop",
    description: "Easily showcase your cafe online with stunning designs. Add your menu, photos, and more!",
    tags: ["Cafes", "Restaurants", "Fast Setup"],
  },
  {
    title: "Company Website",
    img: company,
    buttonText: "View Solutions",
    link: "/ProductShop",
    description: "Create a professional online presence for your company with easy designs. Just add your info and attract clients!",
    tags: ["Companies", "Business", "Web Solutions"],
  },
];

export const Promotion = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const slideInterval = useRef(null);
  const totalSlides = slides.length;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const clonedSlides = [
    slides[totalSlides - 1], 
    ...slides,
    slides[0], 
  ];

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide(); 
  }, []);

  const startAutoSlide = () => {
    slideInterval.current = setInterval(() => {
      nextSlide();
    }, 10000); 
  };

  const stopAutoSlide = () => {
    clearInterval(slideInterval.current);
  };

  const nextSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => prev + 1);
  };

  const prevSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => prev - 1);
  };

  useEffect(() => {
    if (currentSlide === 0) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentSlide(totalSlides);
      }, 300);
    } else if (currentSlide === totalSlides + 1) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentSlide(1);
      }, 300);
    } else {
      setTimeout(() => setIsTransitioning(false), 300); 
    }
  }, [currentSlide, totalSlides]);

  const getTitleFontSize = () => {
    if (screenWidth <= 480) {
      return "1.4em"; 
    } else if (screenWidth <= 768) {
      return "1.8em"; 
    } else {
      return "2.5em"; 
    }
  };

  const getContainerStyles = () => {
    return {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      overflow: "hidden",
    };
  };

 const getSlideStyles = (isCenter) => {
  return {
    width: screenWidth <= 768 ? '100%' : '33.33%',
    padding: screenWidth <= 768 ? '1em' : '4px',
    opacity: isCenter ? 1 : (screenWidth <= 768 ? 1 : 0.5),
    filter: isCenter ? "none" : (screenWidth <= 768 ? "none" : "blur(2px)"),
    transform: `scale(${isCenter && screenWidth > 768 ? 1.05 : 1})`,
    height: '100%', 
  };
};
  const getCarouselWidth = () => {
    if (screenWidth <= 480) {
      return `${clonedSlides.length * 18.33}%`; 
    } else if (screenWidth <= 768) {
      return `${clonedSlides.length * 15}%`;
    } else if (screenWidth <= 1024) {
      return `${clonedSlides.length * 23.33}%`; 
    } else {
      return `${clonedSlides.length * 33.33}%`; 
    }
  };

  const getTransformValue = () => {
    if (screenWidth <= 480) {
      return `translateX(-${(currentSlide - 1) * 100}%)`; 
    } else if (screenWidth <= 768) {
      return `translateX(-${(currentSlide - 1) * 100}%)`; 
    } else if (screenWidth <= 1024) {
      return `translateX(-${(currentSlide - 1) * 33.33}%)`; 
    } else {
      return `translateX(-${(currentSlide - 1) * 33.33}%)`; 
    }
  };

  return (
    <section
      className="relative w-full h-full flex justify-center items-center px-4 md:px-16"
      style={{ maxWidth: "100%", margin: "0 auto", overflow: "hidden" }}
    >
        <>
          <button
            onClick={prevSlide}
            className="absolute left-2 z-10 p-2 text-white bg-black rounded-full"
          >
            &#10094;
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-2 z-10 p-2 text-white bg-black rounded-full"
          >
            &#10095;
          </button>
        </>

      <div style={getContainerStyles()}>
        <div className="flex justify-center items-center relative w-full">
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{
              transform:  getTransformValue(),
              width:  getCarouselWidth()
            }}
          >
            {clonedSlides.map((slide, index) => {
              const isCenter = index === currentSlide;

              return (
                <div
                  key={index}
                  className={`flex-shrink-0 transition-all ease-in-out`}
                  style={getSlideStyles(isCenter)}
                >
                  
                 <div
                    className="relative rounded-lg  p-4 flex"
                    style={{
                      background: isCenter
                        ? 'linear-gradient(103deg, rgb(180, 178, 178) 0%, #49132e 100%)'
                        : 'linear-gradient(103deg, rgb(180, 178, 178) 0%, #49132e 100%)',
                      transition: "background 0.5s ease, transform 0.5s ease",
                      cursor: 'pointer',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.background = 'linear-gradient(103deg, rgb(180, 178, 178) 0%, #711E48 100%)';
                      e.currentTarget.style.transform = 'scale(1.02)';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.background = isCenter
                        ? 'linear-gradient(103deg, rgb(180, 178, 178) 0%, #49132e 100%)'
                        : 'linear-gradient(103deg, rgb(180, 178, 178) 0%, #49132e 100%)';
                      e.currentTarget.style.transform = 'scale(1)';
                    }}
                  >
                    <div className="flex flex-col md:flex-row justify-between w-full relative z-10">
                      <div className="flex flex-col items-start md:max-w-[60%] md:pr-2">
                        <HoverableHeading
                          title={slide.title}
                          fontSize={getTitleFontSize()}
                          colorClassName="text-800"
                        />
                        <p className="text-white mt-2 text-sm md:text-base text-justify"
                          style={{ fontFamily: 'Poppins'}}
                        >
                          {slide.description}
                        </p>
                        <div className="flex flex-wrap justify-start mt-2">
                          {slide.tags.map((tag, idx) => (
                            <span key={idx} className="text-xs font-bold px-2 py-1 rounded-full mr-1 mb-1" style={{
                              color: 'white',
                              backgroundColor: '#b4b2b2',
                            }}>
                              {tag}
                            </span>
                          ))}
                        </div>
                        <a 
  href={slide.link} 
  className="promobut inline-block bg-[#49132e] text-white rounded transition-transform duration-300 ease-in-out 
             hover:bg-[#711E48] hover:scale-105 
             mt-4 ml-4 sm:mt-6 sm:ml-6 md:mt-20 md:ml-16 
             px-4 py-2 sm:px-6 sm:py-3 md:px-4 md:py-2"
>
  {slide.buttonText}
</a>
                      </div>

                      <div className="flex items-center justify-center mt-4 md:mt-0">
                        <img
                          className="object-cover transition-transform duration-300 hover:scale-110"
                          style={{ maxWidth: '100%', height: 'auto' ,scale: screenWidth <= 768 ? '1.1' : '1.3', 
                            maxHeight: '350px', 
                            objectFit: 'cover'
                          }}
                          src={slide.img}
                          alt={slide.title}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};